import React, { useEffect, useState } from 'react';

export default function Redirect() {
  const { url } = window.location.search;

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    
    if (token) {
        setTimeout(() => {
            window.location.href = token;
        }, 3000);
    }
  }, [url]);

  return (
    <div>
      <p>Redirecting...</p>
    </div>
  );
}

