import React, { useState, useEffect } from 'react';
import GridLists from '../components/GridLists.jsx';
import '../App.css';
import { useUserContext } from '../contexts/UserContext.jsx';

import { supabase } from '../libs/api.js';

import { getEnrichedConnections } from '../libs/fetch.js';
import {
  QuestionMarkCircleIcon,
} from '@heroicons/react/20/solid';

const IntegrationsContent = () => {
  const [integrations, setIntegrations] = useState([]);
  const [error, setError] = useState(null);
  const { currentOrganization } = useUserContext();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const enrichedData = await getEnrichedConnections(currentOrganization);
        setIntegrations(enrichedData);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error);
      }
    };
    if (currentOrganization) {
      fetchData();
    }
  }, [currentOrganization]); // Empty dependency array means this effect runs once after the initial render

  const activeIntegrations = integrations?.filter((i) => i.status === "active");
  const setupIntegrations = integrations?.filter((i) => i.status === "setup");
  const availableIntegrations = integrations?.filter((i) => i.status === "available");
  const unavailableIntegrations = integrations?.filter((i) => i.status === "inactive");
  return (
    <>
      <div className="py-10">
        <main>
          { setupIntegrations.length > 0 ?
            <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mb-2">
              <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900 pb-2">In-Progress Data Sources</h1>
            </div>
            <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 mb-10">
                <GridLists integrations={setupIntegrations} /></div>
                </>
            : null
          }
          { activeIntegrations.length > 0 ?
            <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mb-2">
              <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900 pb-2">Active Data Sources</h1>
            </div>
            <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 mb-10">
                <GridLists integrations={activeIntegrations} /></div>
                </>
            : null
          }
          { (availableIntegrations.length > 0 || unavailableIntegrations.length > 0) ?
            <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900 pb-2">Available Data Sources</h1>
            </div>
            </>
            : null
          }
          {
            availableIntegrations.length > 0 ?
            <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 mb-5">
                <GridLists integrations={availableIntegrations} /></div>
            : null
          }
          { unavailableIntegrations.length > 0 ?
            <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pb-2">
              <div className="flex items-center gap-x-1">
              <h1 className="text-1xl font-bold leading-tight tracking-tight text-gray-600 pr-2">Requires Upgrade</h1>
              <a className="-mr-px inline-flex text-sm text-gray-500 cursor-pointer" id="support">
              Contact Support
              <QuestionMarkCircleIcon className="h-4 w-4 text-gray-500" aria-hidden="true"/>
              </a>
              </div>
              
            </div>
            <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 opacity-60">
                <GridLists integrations={unavailableIntegrations} /></div>
                </>
            : null
          }
        </main>
      </div>
    </>
  );
};

export async function getIntegration(id) {
  const integrations = await getEnrichedConnections();
  return integrations.find(i => i.integration_id === id) ?? null;
}

export default function Integrations() {
  return (
    <IntegrationsContent  />
  );
}
