import { useState, useEffect, useRef } from 'react';
import { Combobox } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useUserContext } from '../contexts/UserContext.jsx';
import { useNavigate, useLocation } from 'react-router-dom';
import { updateDefaultOrg } from '../libs/fetch.js';

export default function OrgSelector(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [query, setQuery] = useState('');
  const [orgs, setOrgs] = useState([{ id: 0, name: '' }]);
  const [selectedOrg, setSelectedOrg] = useState({ id: 0, name: '' });
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const { currentOrg, allowedOrganizations, setCurrentOrganization, setLoading, profile } = useUserContext();

  useEffect(() => {
    if (allowedOrganizations) {
      const primaryOrgId = profile?.primary_organization_id;
      let sortedOrgs = allowedOrganizations.sort((a, b) => {
        if (a?.name.toLowerCase() > b?.name.toLowerCase()) {
          return 1;
        } else if (a?.name.toLowerCase() < b?.name.toLowerCase()) {
          return -1;
        } else {
          return 0;
        }
      });

      if (primaryOrgId) {
        sortedOrgs = [
          ...sortedOrgs.filter((org) => org.id === primaryOrgId),
          ...sortedOrgs.filter((org) => org.id !== primaryOrgId),
        ];
      }

      setOrgs(sortedOrgs);
      setSelectedOrg(currentOrg);
    }
  }, [currentOrg, allowedOrganizations, profile]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  if (orgs == null) {
    return null;
  }

  const filteredOrgs =
    query === ''
      ? orgs
      : orgs.filter((org) => org.name.toLowerCase().includes(query.toLowerCase()));

  const changeOrg = async (org) => {
    setQuery('');
    setSelectedOrg(org);
    setDropdownOpen(false);
    setLoading(true);
    setCurrentOrganization(org.id);
    await updateDefaultOrg(org.id);
    const currentParams = new URLSearchParams(location.search);
    navigate(`/home?${currentParams.toString()}`);
  };

  return (
    <div className="w-full max-w-xs mx-auto">
      { orgs.length === 0 ? null :
      orgs.length === 1 ? (
        <div className="ml-1 text-sm text-gray-500">{orgs[0]?.name}</div>
      ) : (
        <>
          <button
            className="py-2 pl-3 pr-1 text-sm leading-5 text-gray-900 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primaryBrandColor"
            onClick={() => setDropdownOpen(!dropdownOpen)}
          >
            {selectedOrg?.name || 'Select an organization'}
            <ChevronDownIcon className="h-5 w-5 text-gray-400 inline-block ml-2" aria-hidden="true" />
          </button>
          {dropdownOpen && (
            <div className="relative">
              <Combobox value={selectedOrg} onChange={changeOrg}>
                <div className="absolute z-10 mt-1 max-h-60 w-64 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm right-0"
                    ref={dropdownRef}
                  >
                  <div className="px-3 py-2">
                    <input
                      type="text"
                      className="w-full py-2 pl-3 pr-3 text-sm leading-5 text-gray-900 bg-gray-50 focus-visible:ring-0 focus-visible:none focus-visible:outline-none rounded-md"
                      placeholder="Search organizations..."
                      value={query}
                      onChange={(e) => setQuery(e.target.value)}
                    />
                  </div>
                  {filteredOrgs.map((org) => (
                    <Combobox.Option
                      key={org.id}
                      value={org}
                      className={({ active, selected }) =>
                        `relative cursor-default select-none py-2 pl-10 list-none pr-4 ${
                          active ? 'bg-primaryBrandColor text-white' : 'text-gray-900'
                        } ${selected ? 'font-medium' : 'font-normal'}`
                      }
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? 'font-medium' : 'font-normal'
                            }`}
                          >
                            {org.name}
                          </span>
                          {selected && (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-secondaryBrandColor">
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          )}
                        </>
                      )}
                    </Combobox.Option>
                  ))}
                </div>
              </Combobox>
            </div>
          )}
        </>
      )}
    </div>
  );
}

function CheckIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      aria-hidden="true"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5 13l4 4L19 7"
      />
    </svg>
  );
}
