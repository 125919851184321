import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../libs/api.js'
import { useUserContext } from '../contexts/UserContext.jsx';
import Muffin from '../assets/primary_logo.png';
import { buttonStyle} from '../libs/sharedStyles.js';
import { primaryButtonClasses, secondaryButtonClasses } from '../libs/sharedClassNames.js';
import Loading from '../components/Loading.jsx';
import posthog from 'posthog-js'

export default function Signin() {
  const { loading , setLoading} = useUserContext();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const { user } = useUserContext();

  useEffect(() => {
    if (!user) {
      setLoading(false);
    } else {
      posthog.identify(
        user.user.id,
        { name: user.user.user_metadata.first_name, email: user.user.identities[0].email }
      );
      navigate('/home');
    }

  }, [user]);

  async function signInWithEmail(event) {
    event.preventDefault();
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    if (error) {
      setErrorMessage(error.message)
    }
  }

  return (
    loading ? <Loading /> :
      <>
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img
              className="mx-auto h-[6rem] w-auto"
              src={Muffin}
              alt="Muffin Data"
            />
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Sign in to your account
            </h2>
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form className="space-y-6" onSubmit={signInWithEmail}>
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    placeholder="your-email@your-domain.com"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-700 sm:text-sm sm:leading-6 px-3 py-2"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                    Password
                  </label>
                </div>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    placeholder="***************"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-700 sm:text-sm sm:leading-6 px-3 py-2"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>
                {errorMessage && (
                  <div className="mb-4 text-sm font-medium text-red-600">
                    {errorMessage}
                  </div>
                )}
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <button
                    type="submit"
                    style={buttonStyle}
                    className={primaryButtonClasses}
                  >
                    Sign In
                  </button>
                </div>
                <div className="text-sm leading-6">
                  <a href="/forgot-password" className="font-semibold text-primaryBrandColor">
                    Forgot password?
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
  );
}
