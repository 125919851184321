import React, { useContext, useState, useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useUserContext } from '../contexts/UserContext';

const ExportsGuard = ()  =>{
  const { allowedNavigation } = useUserContext();
  const isAllowed = allowedNavigation.some((nav) => nav.id === 'fe__exports__view');

  if (!isAllowed) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
}

export default ExportsGuard;
