import { lazy, Suspense, useEffect } from 'react';
import Loading from '../../components/Loading.jsx';
import posthog from 'posthog-js';

const OnboardingFormHotglue = lazy(() => import('../../components/OnboardingFormHotglue.jsx'));

export default function BigQuery() {

  useEffect(() => {
    posthog.capture('$pageview');
  }, []);

  return (
    <>
      <Suspense fallback={<Loading/>}>
      <main>
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900 mt-2">Connect Muffin Data to Google BigQuery</h1>
            <p className="mt-6">
              Export your Muffin Data to Google BigQuery
            </p>
            <OnboardingFormHotglue target='bigquery' flow_id='pwqI1FZ23' defaultValues={{uri: 'US'}}/>
        </div>
      </main>
      </Suspense>
    </>
  );
}
