import { useEffect } from 'react';
import posthog from 'posthog-js';
import OrgSelector from '../components/OrgSelector.jsx';
import OrgList from '../components/OrgList.jsx';

export default function SelectOrganization() {
  useEffect(() => {
    posthog.capture('$pageview');
  }, []);

  return (
    <div className="mx-auto sm:px-6 lg:px-8 max-w-[70rem]">
      <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900 mt-10 mb-6">
        Select a Brand
      </h1>
      <div className="bg-white shadow-md rounded-lg p-6">
        <p className="text-gray-600 mb-6">
          To proceed, please select a brand to view their integrations and data.
          <br />
          <span className="text-gray-500 text-md">(You can always change this later in settings)</span>
        </p>
        <div className="mb-6">
          <OrgList />
        </div>
        <p className="text-md text-gray-500">
          If you need help or additional access, please {' '}
            <a href="mailto:founders@muffindata.com" className="text-sm font-semibold text-gray-900">
              contact support.</a>
        </p>
      </div>
    </div>
  );
}
