import React from 'react';

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function StatusBadge({ status }) {
  if (status === 'active') {
    return (
      <span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
        {"Connected"}
      </span>
    );
  } else if (status === "setup") {
    return (
      <span className="inline-flex flex-shrink-0 items-center rounded-full bg-yellow-50 px-1.5 py-0.5 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/20">
        {"Connection In-Progress"}
      </span>
    );
  } else if (status === "error") {
    return (
      <span className="inline-flex flex-shrink-0 items-center rounded-full bg-red-50 px-1.5 py-0.5 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
        {capitalizeFirstLetter(status)}
      </span>
    );
  } else if (status === 'available') {
    return (
      <span className="inline-flex flex-shrink-0 items-center rounded-full bg-yellow-50 px-1.5 py-0.5 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/20">
        {"Ready to Connect"}
      </span>
    );
  }
}
export default StatusBadge;