import { Fragment, useState, useEffect } from 'react';
import { primaryButtonClasses, secondaryButtonClasses } from '../libs/sharedClassNames.js';
import { buttonStyle } from '../libs/sharedStyles.js';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  PaperClipIcon,
} from '@heroicons/react/20/solid';
import { useOrganizationContext } from '../contexts/OrganizationContext.jsx';
import { useUserContext } from '../contexts/UserContext.jsx';
import OrgSelector from "../components/OrgSelector.jsx";
import posthog from 'posthog-js';

export default function Settings() {
  const { user, profile } = useUserContext();
  let orgName;
  if (profile?.default_organization_id) {
    orgName = profile?.allowed_organizations.find((org) => org.id === profile.default_organization_id).name;
  } else {
    orgName = profile?.allowed_organizations.find((org) => org.id === profile.primary_organization_id).name;
  }
  const navigate = useNavigate();

  useEffect(() => {
    posthog.capture('$pageview');
  }, []);


  return (
    <>
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900 mt-2">User Profile & Settings</h1>
        <div className="mt-10">
          <div className="px-4 sm:px-0">
            <h2 className="text-xl font-bold leading-tight tracking-tight lg:pb-8">Settings</h2>
          </div>
          <div className="border-t border-gray-100">
            <button
              style={buttonStyle}
              onClick={() => navigate('/reset-password')}
              className={primaryButtonClasses}>
              Reset Password
            </button>
          </div>
          <div className="px-4 sm:px-0 mt-10">
            <h2 className="text-xl font-bold leading-tight tracking-tight lg:pb-8">User Details</h2>
          </div>
          <div className="border-t border-gray-100">
            <dl className="divide-y divide-gray-100">
              {profile?.allowed_organizations.length > 1 ? 
              <div className="px-4 py-6 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Default Organization</dt>
                <dd className=""><OrgSelector /></dd>
              </div>
                : <div className="px-4 py-6 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Organization</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{orgName}</dd>
              </div>
              }
              <div className="px-4 py-6 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Full name</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{profile?.first_name}{' '}{profile?.last_name}</dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Email address</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{user?.user?.email}</dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </>
  );
}
