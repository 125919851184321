import { useState, useEffect } from 'react';
import { useUserContext } from '../contexts/UserContext.jsx';
import { useNavigate, useLocation } from 'react-router-dom';
import { updateDefaultOrg } from '../libs/fetch.js';

export default function OrgSelector() {
  const navigate = useNavigate();
  const location = useLocation();
  const [query, setQuery] = useState('');
  const [orgs, setOrgs] = useState([]);
  const { currentOrg, allowedOrganizations, setCurrentOrganization, setLoading, profile } = useUserContext();

  useEffect(() => {
    if (allowedOrganizations) {
      const primaryOrgId = profile?.primary_organization_id;
      let sortedOrgs = allowedOrganizations.sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      );

      if (primaryOrgId) {
        sortedOrgs = [
          ...sortedOrgs.filter((org) => org.id === primaryOrgId),
          ...sortedOrgs.filter((org) => org.id !== primaryOrgId),
        ];
      }

      setOrgs(sortedOrgs);
    }
  }, [allowedOrganizations, profile]);

  const filteredOrgs =
    query === ''
      ? orgs
      : orgs.filter((org) => org.name.toLowerCase().includes(query.toLowerCase()));

  const changeOrg = async (org) => {
    setQuery('');
    setLoading(true);
    setCurrentOrganization(org.id);
    const currentParams = new URLSearchParams(location.search);
    await updateDefaultOrg(org.id);
    navigate(`/home?${currentParams.toString()}`);
  };

  return (
    <div className="flex flex-col items-center">
      <input
        type="text"
        className="w-full max-w-md py-2 pl-3 pr-3 mb-4 text-sm leading-5 text-gray-900 bg-gray-50 rounded-md border border-gray-300 focus:ring-1 focus:ring-primaryBrandColor focus:outline-none"
        placeholder="Search organizations..."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      <div className="w-full max-h-60 max-w-md overflow-y-auto bg-white shadow-sm rounded-md border border-gray-200">
        {filteredOrgs.length === 0 ? (
          <p className="p-4 text-gray-500">No organizations found</p>
        ) : (
          filteredOrgs.map((org) => (
            <div
              key={org.id}
              onClick={() => changeOrg(org)}
              className={`cursor-pointer px-4 py-2 text-sm text-gray-900 hover:bg-primaryBrandColor hover:text-white ${
                currentOrg.id === org.id ? 'bg-primaryBrandColor text-white' : ''
              }`}
            >
              {org.name}
            </div>
          ))
        )}
      </div>
    </div>
  );
}

