import React, { createContext, useContext } from 'react';
import DH_Logo from "../assets/dirty_hands/DH_Logo_Classic_Grn+Gry.png";

const UserContext = createContext();

let navigation = [];

export const useUserContext = () => useContext(UserContext);
export const UserProvider = props => {
  const { user, profile, currentOrganization, loading, setLoading, setCurrentOrganization } = props.value;
  const allowedOrganizations = profile?.allowed_organizations ?? [];
  const currentOrg = allowedOrganizations.find((org) => org.id === currentOrganization);
  const currentPermissions = currentOrg?.permissions ?? [];

  navigation = [];

  const addNavigationItem = (item) => {
    if (!navigation.some(navItem => navItem.id === item.id)) {
      navigation.push(item);
    }
  };

  // Analytics routes
  if (currentPermissions.includes('fe__analytics__view') && profile?.user_analytics_active) {
    addNavigationItem({ name: 'Analytics', href: '/analytics', id: 'fe__analytics__view' });
  }

  // TODO allow for multiple parents
  if (profile && profile.available_embeds && profile.available_embeds.length > 0) {
    const subNavigation = [];
    profile.available_embeds.map((embed) => {
      subNavigation.push({
        name: embed.name,
        description: embed.description,
        href: `/analytics/${embed.slug}`,
        id: `embed__${embed.id}__view`,
        image: DH_Logo
      });
    });

    // use first embed slug to populate the head nav item
    const firstHref = profile.available_embeds[0].slug;
    addNavigationItem({
      name: 'Dirty Hands',
      href: `/analytics/${firstHref}`,
      id: 'embed__view__container',
      subNavigation: subNavigation
    });
  }

  if (currentPermissions.includes('fe__integrations__view')) {
    addNavigationItem({ name: 'Integrations', href: '/integrations', id: 'fe__integrations__view' });
  }
  if (currentPermissions.includes('fe__exports__view')) {
    addNavigationItem({ name: 'Destinations', href: '/destinations', id: 'fe__exports__view' });
  }

  return (
    <UserContext.Provider value={{
      user,
      profile,
      currentOrganization,
      loading,
      setLoading,
      setCurrentOrganization,
      allowedOrganizations,
      currentOrg,
      currentPermissions,
      allowedNavigation: navigation
    }}>
      {props.children}
    </UserContext.Provider>
  );
};
