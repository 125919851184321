import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Loading from '../components/Loading.jsx';
import { useUserContext } from '../contexts/UserContext.jsx';

export default function Signin() {
  const navigate = useNavigate();
  const location = useLocation();
  const { allowedNavigation, profile } = useUserContext();

  useEffect(() => {
    if (allowedNavigation?.length > 0) {
      const currentParams = new URLSearchParams(location.search);
      navigate(`${allowedNavigation[0].href}?${currentParams.toString()}`, { replace: true, preventScrolReset: true});
    } else if (profile?.allowed_organizations.length > 1) {
      console.log("go to org selection page")
      navigate(`/select-organization`);
    }

  }, [allowedNavigation]);

  return (
    <Loading />
  );
}
