import { Fragment, useState, useEffect } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { buttonStyle} from '../libs/sharedStyles.js';
import { primaryButtonClasses, secondaryButtonClasses } from '../libs/sharedClassNames.js';
import Muffin from '../assets/primary_logo.png';
import { PhotoIcon } from '@heroicons/react/24/solid'
import { Bars3Icon, BellIcon, XMarkIcon, UserCircleIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom';
import { supabase } from "../libs/api";
import { useUserContext } from '../contexts/UserContext.jsx';
import posthog from 'posthog-js'

export default function ResetPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const { user } = useUserContext();

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const resetPassword = async(e) => {
    e.preventDefault();
    const { data, error } = await supabase.auth.updateUser({
      password: password
    });
    if (error) {
      setErrorMessage(error.message)
    } else {
      navigate('/');
    }
  };

  useEffect(() => {
    if (!user) {
      setLoading(false);
    } else {
      posthog.identify(
        user.user.id,
        { name: user.user.user_metadata.first_name, email: user.user.identities[0].email }
      );
    }
  }, [user]);

  return (
    <>
      <div className="mx-auto max-w-7xl">
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-12">
          <form onSubmit={resetPassword}>
            <div className="space-y-12">
              <div className="border-b border-gray-900/10 pb-12">
                <h2 className="text-xl font-bold leading-tight tracking-tight lg:pb-8">Reset Password</h2>
                <p className="text-sm leading-6 text-gray-600">
                  8 characters minimum and can't be a password you have used before.
                </p>

                <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-4">
                    <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                      New Password
                    </label>
                    <div className="w-[30rem]">
                      <input
                        id="password"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        autoComplete="current-password"
                        required
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-700 sm:text-sm sm:leading-6 px-3 py-2"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        minlength="8"
                      />
                      <fieldset>
                        <legend className="sr-only">Show Password</legend>
                        <div className="space-y-5 mt-2">
                          <div className="relative flex items-start">
                            <div className="flex h-6 items-center">
                              <input
                                id="showPassword"
                                name="showPassword"
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-100 accent-primaryBrandColor focus:ring-brandNeutral"
                                onChange={togglePasswordVisibility}
                                checked={showPassword}
                              />
                            </div>
                            <div className="ml-3 text-sm leading-6">
                              <label htmlFor="showPassword" className="font-medium text-gray-900">
                                Show Password
                              </label>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3">
              {errorMessage && (
                <div className="text-sm font-medium text-red-600">
                  {errorMessage} {/* Display the error message here */}
                </div>
              )}
            </div>
            <div className="mt-6 flex items-center justify-start">

              <button
                type="submit"
                style={buttonStyle}
                className={primaryButtonClasses}
              >
                Update
              </button>
              <button type="button" style={buttonStyle} className={secondaryButtonClasses} onClick={() => navigate('/')}>
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
};
