import React from 'react';

const VERSION = "1.0.0";
export default function TermsOfService() {
  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-3xl font-bold mb-4">Muffin Data Inc. Terms of Service</h1>

      <p className="font-semibold mb-4">Last Updated: November 6, 2024</p>

      <p className="mb-4">
        If you signed a separate Cover Page to access the Product with the same account, and that agreement has not ended, the terms below do not apply to you. Instead, your separate Cover Page applies to your use of the Product.
      </p>

      <p className="mb-4">
        This Agreement is between Muffin Data Inc. and the company or person accessing or using the Product. This Agreement consists of: (1) the Order Form below and (2) the Framework Terms defined below.
      </p>

      <p className="mb-4">
        If you are accessing or using the Product on behalf of your company, you represent that you are authorized to accept this Agreement on behalf of your company. By signing up, accessing, or using the Product, Customer indicates its acceptance of this Agreement and agrees to be bound by the terms and conditions of this Agreement.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">Cover Page</h2>

      <p className="italic mb-2">Order Form</p>

      <p className="mb-4">
        <strong>Framework Terms:</strong> This Order Form incorporates and is governed by the Framework Terms that are made up of the Key Terms below and the Common Paper{' '}
        <a
          href="https://commonpaper.com/standards/cloud-service-agreement/2.0/"
          className="text-blue-600 underline hover:text-blue-800"
        >
          Cloud Service Agreement Standard Terms Version 2.0
        </a>
        , which are incorporated by reference. Any modifications to the Standard Terms made in the Cover Page will control over conflicts with the Standard Terms. Capitalized words have the meanings given in the Cover Page or the Standard Terms.
      </p>

      <p className="mb-4"><strong>Cloud Service:</strong> A cloud-based platform that consolidates, cleans, and unifies data from Customer's key retail and related data sources.</p>
      <p className="mb-4"><strong>Order Date:</strong> The Effective Date</p>
      <p className="mb-4"><strong>Subscription Period:</strong> 1 month(s)</p>

      <p className="mb-4">
        <strong>Cloud Service Fees:</strong> Customer will pay Provider the fees as set forth in the applicable Order Form (the “Fees”). Upon execution by Customer and Provider, each Order Form is non-cancellable and non-refundable except as otherwise provided in this Agreement or in an applicable Order Form.
      </p>

      <p className="mb-4">
        If Customer ordered a “trial” or other limited period no-charge plan, Customer may use the Service for a fourteen (14) day trial period, or as otherwise expressly set forth in the order (the “Trial Period”), solely for evaluation purposes, starting on the date that Customer registered with Our Service and accepted this Agreement, without charge or further commitment...
      </p>

      <p className="mb-4"><strong>Payment Process:</strong> Bill by invoice: Provider will invoice Customer monthly. Customer will pay each invoice within 30 day(s) from date of invoice.</p>

      <p className="mb-4"><strong>Non-Renewal Notice Period:</strong> At least 30 days before the end of the current Subscription Period.</p>

      <p className="mb-4">
        <strong>Use Limitations:</strong> Unless otherwise specified in an Order Form, the Cloud Service may be accessed by no more than the specified number of Users and each User must be identified by a unique email address and username. User subscriptions may only be purchased as set forth in the applicable Order Form and may not be shared or used by more than one User without Provider’s prior consent...
      </p>

      <p className="italic mb-2">Key Terms</p>

      <p className="mb-4"><strong>Customer:</strong> The company or person who accesses or uses the Product. If the person accepting this Agreement is doing so on behalf of a company, all use of the word "Customer" in the Agreement will mean that company.</p>
      <p className="mb-4"><strong>Provider:</strong> Muffin Data Inc.</p>
      <p className="mb-4"><strong>Effective Date:</strong> The date Customer first accepts this Agreement.</p>
      <p className="mb-4"><strong>Governing Law:</strong> The laws of the State of Delaware</p>
      <p className="mb-4"><strong>Chosen Courts:</strong> The state or federal courts located in Delaware</p>

      <p className="mb-2"><strong>Covered Claims:</strong></p>
      <p className="ml-6 mb-2"><strong>Provider Covered Claims:</strong> Any action, proceeding, or claim that the Cloud Service, when used by Customer according to the terms of the Agreement, violates, misappropriates, or otherwise infringes upon anyone else’s intellectual property or other proprietary rights.</p>
      <p className="ml-6 mb-4"><strong>Customer Covered Claims:</strong> Any action, proceeding, or claim that (1) the Customer Content, when used according to the terms of the Agreement, violates, misappropriates, or otherwise infringes upon anyone else’s intellectual property or other proprietary rights; or (2) results from Customer’s breach or alleged breach of Section 2.1 (Restrictions on Customer).</p>

      <p className="mb-4"><strong>General Cap Amount:</strong> The fees paid or payable by Customer to provider in the 12 month period immediately before the claim</p>
      <p className="mb-4"><strong>Increased Claims:</strong> Breach of Section 3 (Privacy & Security) (however, excluding any data or security breaches)</p>
      <p className="mb-4"><strong>Increased Cap Amount:</strong> 5.0 times the fees paid or payable by Customer to provider in the 12 month period immediately before the claim</p>

      <p className="mb-4"><strong>Unlimited Claims:</strong> Breach of Section 10 (Confidentiality) resulting from gross negligence or willful misconduct (however, excluding any data or security breaches) and Breach of Section 3 (Privacy & Security) resulting from gross negligence or willful misconduct</p>

      <p className="mb-4"><strong>Notice Address:</strong></p>
      <p className="ml-6 mb-4">For Provider: support@muffindata.com</p>
      <p className="ml-6 mb-4">For Customer: The main email address on Customer's account</p>

      <p className="italic mb-2">Changes to the Standard Terms</p>
      <p className="mb-4">
        Add the following to Section 1.5: "Customer shall i) provide Provider credentials (including changes to such credentials, such as password changes) to access Customer Content from third party sources (such as retailer portals, distributor portals, and the like) and ii) provide answers to Provider's questions relating to Customer Content for the purposes of designing the Cloud Service for the Customer's use...
      </p>
    </div>
  );
}

